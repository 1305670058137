import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { cssClass, calculateCommission } from 'helpers/helper'

import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './MarketLimit.module.scss'

import CommissionTypes from 'data/CommissionTypes'

import { observer } from 'mobx-react'
import store from '../store'
import i18n from 'i18next'
const tabs = ['Market', 'Limit']

const MarketLimit = observer(({ close, typeAction = 'buy' }) => {
  const [tab, setTab] = useState(tabs[0])
  const [type, setType] = useState(typeAction)

  const { t } = useTranslation()

  useEffect(() => {}, [typeAction])

  return (
    <div className={styles.wrapper}>
      <div className={styles.borderWrapper}>
        <div className={styles.navWrapper}>
          {tabs.map((element, index) => {
            const activityClass = element === tab ? styles.navButtonActive : null
            return (
              <button key={index} onClick={() => setTab(element)} className={cssClass(styles.navButton, activityClass)}>
                <span>{t(element)}</span>
              </button>
            )
          })}
        </div>
        <div className={styles.typeActionButtonsWrapper}>
          <button
            onClick={() => {
              if (type === 'sell') setType('buy')
              else close()
            }}
            className={cssClass(styles.green, type === 'buy' ? styles.activeGreen : null)}
          >
            {t('Buy')}
          </button>
          <button
            onClick={() => {
              if (type === 'buy') setType('sell')
              else close()
            }}
            className={cssClass(styles.red, type === 'sell' ? styles.activeRed : null)}
          >
            {t('Sell')}
          </button>
        </div>
        <div className={styles.content}>
          <div className={styles.crossBg}></div>
          <div className={styles.table}>
            <div className={cssClass(styles.titleRow, styles.titleRowLeft, styles.title1)}>
              <span className={styles.tableTitle}>
                {type === 'buy' ? `${t('Buy')} ${store.symbolBase}` : `${t('Sell')} ${store.symbolBase}`}
              </span>
              <div className={styles.balanceWrapper}>
                <span className={styles.balanceTitle}>{t('Balance')}:</span>
                <span className={styles.balanceValue}>{store.getFreeBalance(store.symbolQuote) ?? ''}</span>
                <span className={styles.balanceCurrency}>{store.symbolQuote}</span>
              </div>
            </div>
            <div className={styles.line1}></div>
            <div className={cssClass(styles.titleRow, styles.titleRowRight, styles.title2)}>
              <span className={styles.tableTitle}>
                {type === 'sell' ? `${t('Buy')} ${store.symbolBase}` : `${t('Sell')} ${store.symbolBase}`}
              </span>
              <div className={styles.balanceWrapper}>
                <span className={styles.balanceTitle}>{t('Balance')}:</span>
                <span className={styles.balanceValue}>{store.getFreeBalance(store.symbolBase) ?? ''}</span>
                <span className={styles.balanceCurrency}>{store.symbolBase}</span>
              </div>
            </div>
            <div className={styles.line2}></div>
            <MarketLimitForm isMarket={tab === tabs[0]} isBid={type === 'buy'} isOnLeft={true} />
            <div className={styles.line3}> </div>
            <MarketLimitForm isMarket={tab === tabs[0]} isBid={false} isOnLeft={false} />
          </div>
        </div>
      </div>
    </div>
  )
})

const Percent = () => {
  return (
    <div className={styles.percentWrapper}>
      <div className={styles.progressLine}></div>
      <div className={cssClass(styles.circle, styles.active)}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <span className={styles.active}>0%</span>
      <span>25%</span>
      <span>50%</span>
      <span>75%</span>
      <span>100%</span>
    </div>
  )
}

const Field = ({ name, value, currency, marginTop, disabled, readOnly, onChange, onBlur, maxBtn, onClick }) => {
  return (
    <div className={cssClass(styles.fieldWrapper, marginTop)}>
      <span className={styles.fieldName}>{name}</span>
      <input
        type='number'
        pattern='[0-9]+([\.][0-9]{1,2})?'
        className={cssClass(
          baseComponents.input,
          styles.fieldInput,
          maxBtn && styles.fieldInputMax,
          styles.hideNumberInputArrows,
        )}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readOnly}
      />
      <span className={cssClass(styles.fieldCurrency, maxBtn && styles.fieldCurrencyMax)}>{currency}</span>
      {maxBtn && (
        <button className={cssClass(baseComponents.button, styles.fieldMaxBtn)} onClick={onClick}>
          <p>MAX</p>
        </button>
      )}
    </div>
  )
}

const Commission = observer(({ currencyCode, commAmount, percent, minimum }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={styles.comissionWrapper}>
      <div className={styles.infoWrapper}>
        <span onClick={() => history.push(`/${i18n.language}/commissions`)} className={styles.infoCommission}>
          {t('Commission')}
        </span>
        <span>{percent ?? ''}%</span>
        <span>~{commAmount?.toFixed(8) ?? ''}</span>
        <span>{currencyCode}</span>
      </div>
    </div>
  )
})

export const MarketLimitForm = observer(({ isMarket, isBid, isOnLeft }) => {
  const { t } = useTranslation()

  const [disabled, setDisabled] = useState(false)
  const [amount, setAmount] = useState('')
  const [amountAfterFee, setAmountAfterFee] = useState(0)
  const [price, setPrice] = useState('')
  const [total, setTotal] = useState(0)
  const [totalAfterFee, setTotalAfterFee] = useState(0)
  const [isChangingTotal, setIsChangingTotal] = useState(false)
  const [toPutMaxValue, setToPutMaxValue] = useState(false)
  const { digitsAmount, digitsPrice, digitsTotal } = store.getPairDigits(store.symbol)

  // get commission
  const currencyCode = isBid ? store.symbolBase : store.symbolQuote
  const commType = isBid ? CommissionTypes.DealAsk : CommissionTypes.DealBid
  //console.log('comm', store.commissions)
  let { commAmount, percent, minimum } = calculateCommission(
    store.commissions?.commissions,
    amount ?? 0,
    commType,
    currencyCode,
    null,
    store.commissions?.userNoTradeCommissions,
  )

  const onAmountChange = (e) => {
    const { value } = e.target
    if (Number(value) == NaN) return
    setIsChangingTotal(false)
    setAmount(value)
  }

  const onPriceChange = (e) => {
    const { value } = e.target
    if (Number(value) == NaN) return
    setPrice(value)
  }

  const onTotalChange = (e) => {
    const { value } = e.target
    const totalNum = Number(value)
    if (totalNum == NaN) return
    setIsChangingTotal(true)
    setTotal(value)
  }

  const updateAmountFromTotal = () => {
    if (!isChangingTotal) return
    const isValidState = Number(price) > 0 && Number(total) > 0
    if (!isValidState) {
      setAmount('')
      setAmountAfterFee(0)
      return
    }

    let newAmount = isBid
      ? (total / price) * (1 - percent / 100) // decrease total by commission
      : total / price / (1 - percent / 100) // increase total by commission

    setTotalAfterFee(0) // if we change total, we show total before fee
    setAmountAfterFee(newAmount.toFixedTrimmed(digitsAmount))
    setAmount((total / price).toFixedTrimmed(digitsAmount))
  }

  const updateTotalFromAmount = () => {
    if (isChangingTotal) return
    const isValidState = Number(price) > 0 && Number(amount) > 0
    if (!isValidState) {
      setTotal('')
      setTotalAfterFee(0)
      return
    }

    let totalAfterFee = isBid
      ? (amount / (1 - percent / 100)) * price // increase total by commission
      : amount * (1 - percent / 100) * price // decrease total by commission

    setAmountAfterFee(0) // if we change amount, we use amount before fee
    setTotalAfterFee(totalAfterFee.toFixedTrimmed(digitsTotal))
    setTotal((amount * price).toFixedTrimmed(digitsTotal))
  }

  const putMaxValue = () => {
    setToPutMaxValue(true)
  }

  const putMaxValueFinish = () => {
    if (!toPutMaxValue) return
    const baseBalance = store.getFreeBalance(store.symbolBase)
    const quoteBalance = store.getFreeBalance(store.symbolQuote)
    if (isBid) setTotal(quoteBalance)
    else setAmount(baseBalance)
    setToPutMaxValue(false)
  }

  // calculate market total from amount and orderbook prices
  const estimateMarketPriceFromAmount = () => {
    if (!isMarket) return
    let newTotal = 0
    const amountVal = Number(amount)
    if (amountVal > 0 && store.orders) {
      const orders = store.orders.filter((_) => _.isBid !== isBid)
      const sortedOrders = orders.sort((a, b) => (isBid ? a.price - b.price : b.price - a.price))
      let amountLeft = amountVal
      for (const order of sortedOrders) {
        if (amountLeft <= 0) break
        const amountToUse = Math.min(amountLeft, order.amount)
        amountLeft -= amountToUse
        newTotal += amountToUse * order.price
        //console.log('total', total, amountToUse, order.price)
      }
      // scale total to amount with found orderbook price
      if (amountLeft > 0) newTotal *= amountVal / (amountVal - amountLeft)
    }
    const newPrice = amount == 0 ? '' : (newTotal / amount).toFixedTrimmed(digitsPrice)

    // if price is same then useEffect of price won't work, so we need to update total manually
    if (price === newPrice) updateTotalFromAmount()
    else setPrice(newPrice)
  }

  // calculate market amount from total and orderbook prices
  const estimateMarketPriceFromTotal = () => {
    if (!isMarket) return
    let newAmount = 0
    const totalVal = Number(total)
    if (totalVal > 0 && store.orders) {
      const orders = store.orders.filter((_) => _.isBid !== isBid)
      const sortedOrders = orders.sort((a, b) => (isBid ? a.price - b.price : b.price - a.price))
      let totalLeft = totalVal
      for (const order of sortedOrders) {
        if (totalLeft <= 0) break
        const totalToUse = Math.min(totalLeft, order.amount * order.price)
        totalLeft -= totalToUse
        newAmount += totalToUse / order.price
        //console.log('total', total, totalToUse, order.price)
      }
      // scale amount to total with found orderbook price
      if (totalLeft > 0) newAmount *= totalVal / (totalVal - totalLeft)
    }
    const newPrice = newAmount == 0 ? '' : (total / newAmount).toFixedTrimmed(digitsPrice)

    // if price is same then useEffect of price won't work, so we need to update amount manually
    if (price === newPrice) updateAmountFromTotal()
    else setPrice(newPrice)
  }

  const addOrder = () => {
    if (!(Number(amount) > 0 && Number(price) > 0)) return

    let amountForBackend
    if (isBid) {
      amountForBackend = isChangingTotal ? amount : totalAfterFee / price
    } else {
      amountForBackend = isChangingTotal ? amountAfterFee : amount
    }
    amountForBackend = Number(amountForBackend).toFixedTrimmed(digitsAmount)

    setDisabled(true)
    store
      .createOrder({ isMarket, isBid, amount: Number(amountForBackend), price: price || 0 })
      .then(() => {
        // notification is handled in store method
        setAmount('')
        setPrice('')
        setDisabled(false)
      })
      .catch(() => {
        setDisabled(false)
      })
  }

  useEffect(() => {
    if (isChangingTotal) return

    if (isMarket) {
      estimateMarketPriceFromAmount() // market orders: estimate total on amount change
    } else {
      updateTotalFromAmount() // limit orders: calculate total on amount change
    }
  }, [amount])

  useEffect(() => {
    if (!isChangingTotal) return

    if (isMarket) {
      estimateMarketPriceFromTotal()
    } else {
      updateAmountFromTotal()
    }
  }, [total])

  useEffect(() => {
    if (isChangingTotal) updateAmountFromTotal()
    else updateTotalFromAmount()
  }, [price])

  // update isChangingTotal if Max button was clicked
  useEffect(() => {
    if (!toPutMaxValue) return
    // for bid we set max total, for ask we set max amount
    if ((isBid && !isChangingTotal) || (!isBid && isChangingTotal)) setIsChangingTotal(!isChangingTotal)
    else putMaxValueFinish()
  }, [toPutMaxValue])

  // set max amount or total after changing total if Max button was clicked
  useEffect(() => {
    if (!toPutMaxValue) return
    putMaxValueFinish()
  }, [isChangingTotal])

  // clear data on tab change
  useEffect(() => {
    setAmount(0)
    setAmountAfterFee(0)
    setPrice(0)
    setTotal('')
    setIsChangingTotal(false)
  }, [isMarket, isBid, store.symbolBase, store.symbolQuote])

  const classes = isOnLeft ? cssClass(styles.cell, styles.cell1) : cssClass(styles.cell, styles.cellRight, styles.cell2)
  return (
    <div className={classes}>
      <p style={{ display: 'none' }}>
        Amount: {amount} | AfterFee: {amountAfterFee}
      </p>
      <Field
        name={t('Quantity')}
        currency={store.symbolBase}
        value={isChangingTotal ? amountAfterFee : amount}
        onChange={(e) => onAmountChange(e, setAmount)}
        onBlur={(e) => {
          /* 
          onChange doesn't format the number because user may be inputting number like 1.00005, 
          where zeroes would be cut on each change.
          So we only format the number on blur (unfocus).
          */
          if (Number(e.target.value) == NaN) return
          setAmount(Number(e.target.value).toFixedTrimmed(digitsAmount))
        }}
        maxBtn
        onClick={putMaxValue}
      />
      <Field
        marginTop={styles.mt10}
        name={t('Rate')}
        currency={store.symbolQuote}
        disabled={isMarket}
        value={price}
        onChange={(e) => onPriceChange(e, setPrice)}
        onBlur={(e) => {
          if (Number(e.target.value) == NaN) return
          setPrice(Number(e.target.value).toFixedTrimmed(digitsPrice))
        }}
      />
      <p style={{ display: 'none' }}>
        Total: {total} | AfterFee: {totalAfterFee}
      </p>
      <Field
        marginTop={styles.mt10}
        name={t('Total')}
        currency={store.symbolQuote}
        value={isChangingTotal ? total : totalAfterFee}
        onChange={(e) => onTotalChange(e)}
        onBlur={(e) => {
          if (Number(e.target.value) == NaN) return
          setTotal(Number(e.target.value).toFixedTrimmed(digitsTotal))
        }}
        onClick={putMaxValue}
        maxBtn
      />
      <Commission currencyCode={currencyCode} commAmount={commAmount} percent={percent} minimum={minimum} />
      {isBid ? (
        <button
          className={cssClass(baseComponents.buttonColored, styles.buttonGreen)}
          onClick={() => addOrder()}
          disabled={false}
        >
          {t('Buy')}
        </button>
      ) : (
        <button
          className={cssClass(baseComponents.buttonColored, styles.buttonRed)}
          onClick={() => addOrder()}
          disabled={false}
        >
          {t('Sell')}
        </button>
      )}
    </div>
  )
})

export default MarketLimit
