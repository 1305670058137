const pairs = ['USDT_USD', 'USDC_USDT']

const allUsedCurrencies = (pairs) => {
  return pairs
    .map((pair) => pair.split('_'))
    .flat()
    .filter((value, index, array) => array.indexOf(value) === index)
    .sort()
}

export default pairs
export { allUsedCurrencies }
