import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './AboutSection.module.scss'
import aboutPicture from './images/about__picture.png'
import aboutImg01 from './images/about__img01.png'
import aboutImg02 from './images/about__img02.png'
import aboutImg03 from './images/about__img03.png'
import { useAuth } from 'auth/auth'
import Scene from './animation/Scene'

export default function AboutSection() {
  const history = useHistory()
  const auth = useAuth()
  const { t } = useTranslation()

  const redirectToRegister = () => {
    history.push(`/${i18n.language}/register`)
  }
  const redirectToConvert = () => {
    history.push(`/${i18n.language}/convert`)
  }

  // coordinates for the phone animation mouse tracking
  const [x, setX] = useState(1000)
  const [y, setY] = useState(0)

  const handleMouseMove = (e) => {
    setX(e.clientX)
    setY(e.clientY)
  }

  return (
    <section className={cssClass(stylesGlobal.layout__section)} onMouseMove={handleMouseMove}>
      <div className={cssClass(stylesGlobal.section__header)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(stylesGlobal.wysiwyg, stylesGlobal.textCenter)}>
            <h1>
              {t('LandingPageTitle1')} <strong>{t('LandingPageTitle2')}</strong> {t('LandingPageTitle3')}{' '}
              <strong>{t('LandingPageTitle4')}</strong>
            </h1>
          </div>
        </div>
      </div>
      <div className={cssClass(stylesGlobal.section__main)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(styles.about)}>
            <div className={cssClass(stylesGlobal.row)}>
              <div
                className={cssClass(
                  stylesGlobal.col,
                  stylesGlobal.col_7,
                  stylesGlobal.col_desktop_6,
                  stylesGlobal.col_mob_12,
                )}
              >
                <div className={cssClass(stylesGlobal.row)}>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_9,
                      stylesGlobal.col_desktop_11,
                      stylesGlobal.col_mob_12,
                    )}
                  >
                    <div className={cssClass(styles.about__picture, styles.desktopOnly)}>
                      {/* <Scene x={x} y={y} /> */}
                      <img src={aboutPicture} alt='app screenshot' />
                    </div>
                    <div className={cssClass(styles.about__picture, styles.phoneOnly)}>
                      <img src={aboutPicture} alt='app screenshot' />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cssClass(
                  stylesGlobal.col,
                  stylesGlobal.col_5,
                  stylesGlobal.col_desktop_6,
                  stylesGlobal.col_mob_12,
                )}
              >
                <div className={cssClass(styles.about__main)}>
                  <div className={cssClass(stylesGlobal.boxes)}>
                    <div className={cssClass(stylesGlobal.boxes__list)}>
                      <div className={cssClass(stylesGlobal.row)}>
                        <div className={cssClass(stylesGlobal.col, stylesGlobal.col_12, stylesGlobal.col_mob_12)}>
                          <div className={cssClass(stylesGlobal.boxes__item)}>
                            <div className={cssClass(stylesGlobal.boxes__media)}>
                              <img src={aboutImg01} alt='image description' />
                            </div>
                            <div className={cssClass(stylesGlobal.boxes__main)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <h2>{t('AboutText1')}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={cssClass(stylesGlobal.col, stylesGlobal.col_12, stylesGlobal.col_mob_6)}>
                          <div className={cssClass(stylesGlobal.boxes__item)}>
                            <div className={cssClass(stylesGlobal.boxes__media)}>
                              <img src={aboutImg02} alt='image description' />
                            </div>
                            <div className={cssClass(stylesGlobal.boxes__main)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <h2>{t('AboutText2')}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={cssClass(stylesGlobal.col, stylesGlobal.col_12, stylesGlobal.col_mob_6)}>
                          <div className={cssClass(stylesGlobal.boxes__item)}>
                            <div className={cssClass(stylesGlobal.boxes__media)}>
                              <img src={aboutImg03} alt='image description' />
                            </div>
                            <div className={cssClass(stylesGlobal.boxes__main)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <h2>{t('AboutText3')}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cssClass(stylesGlobal.section__footer)}>
        <div className={cssClass(stylesGlobal.container)}>
          {auth.token ? (
            <span className={cssClass(stylesGlobal.btn)} onClick={redirectToConvert}>
              <span className={cssClass(stylesGlobal.btn__text)}>{t('Trade')}</span>
              <svg
                className={cssClass(stylesGlobal.btn__ico)}
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewboxes='0 0 25 25'
                fill='none'
              >
                <path
                  d='M8.61561 7.35156H16.4514L5.43059 18.3724C5.35136 18.4516 5.2882 18.5408 5.24112 18.636C5.11416 18.8926 5.10409 19.1923 5.21092 19.4558C5.25974 19.5761 5.33296 19.6889 5.43059 19.7866C5.82111 20.1771 6.45428 20.1771 6.8448 19.7866L17.8656 8.76577V16.6015C17.8656 17.1538 18.3133 17.6015 18.8656 17.6015C19.4179 17.6015 19.8656 17.1538 19.8656 16.6015V6.35156C19.8656 6.31704 19.8639 6.28293 19.8605 6.24931C19.8104 5.75641 19.4024 5.36933 18.9004 5.35215C18.8889 5.35176 18.8773 5.35156 18.8656 5.35156H8.61561C8.06333 5.35156 7.61562 5.79927 7.61562 6.35156C7.61561 6.90384 8.06333 7.35156 8.61561 7.35156Z'
                  fill='white'
                />
              </svg>
            </span>
          ) : (
            <span className={cssClass(stylesGlobal.btn)} onClick={redirectToRegister}>
              <span className={cssClass(stylesGlobal.btn__text)}>{t('Registration')}</span>
              <svg
                className={cssClass(stylesGlobal.btn__ico)}
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewboxes='0 0 25 25'
                fill='none'
              >
                <path
                  d='M8.61561 7.35156H16.4514L5.43059 18.3724C5.35136 18.4516 5.2882 18.5408 5.24112 18.636C5.11416 18.8926 5.10409 19.1923 5.21092 19.4558C5.25974 19.5761 5.33296 19.6889 5.43059 19.7866C5.82111 20.1771 6.45428 20.1771 6.8448 19.7866L17.8656 8.76577V16.6015C17.8656 17.1538 18.3133 17.6015 18.8656 17.6015C19.4179 17.6015 19.8656 17.1538 19.8656 16.6015V6.35156C19.8656 6.31704 19.8639 6.28293 19.8605 6.24931C19.8104 5.75641 19.4024 5.36933 18.9004 5.35215C18.8889 5.35176 18.8773 5.35156 18.8656 5.35156H8.61561C8.06333 5.35156 7.61562 5.79927 7.61562 6.35156C7.61561 6.90384 8.06333 7.35156 8.61561 7.35156Z'
                  fill='white'
                />
              </svg>
            </span>
          )}
        </div>
      </div>
    </section>
  )
}
