import { cssClass } from 'helpers/helper'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import api from 'api'
import Selector from 'baseComponents/Selector/Selector'
import Datepicker from 'components/form/Datepicker/Datepicker'
import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import pages from '../../pages.module.scss'
import styles from './History.module.scss'

import arrowLeftIimg from './images/arrowLeft.svg'
import arrowLeftListIimg from './images/arrowLeftList.svg'

const Tabs = {
  AccountHistory: 'AccountHistory',
  HistoryOfCash: 'HistoryOfCash',
  HistoryOfTurnovers: 'HistoryOfTurnovers',
}

const tabs = [Tabs.AccountHistory, Tabs.HistoryOfCash /*Tabs.HistoryOfTurnovers*/]
const historyTitles = ['DateAndTime', 'Type', 'Currency', 'TotalSum', 'Commission', 'TxID']
const historyCashTitles = ['DateAndTime', 'ReplenishmentAccount', 'Type', 'Currency', 'TotalSum', 'Code', 'Status']
const turnoverTitles = [
  'Date',
  'TurnoverInPairsWithFiat',
  'PurchaseTurnoverInP2P',
  'SalesTurnoverInP2P',
  'TurnoverInCryptocurrencyPairs',
  'FiatDeposits',
  'FiatWithdrawals',
]

export default function History() {
  const { t } = useTranslation()

  const [tab, setTab] = useState(Tabs.AccountHistory)
  const [data, setData] = useState([])
  const [filtredData, setFiltredData] = useState([])
  const [hiddenList, setHiddenList] = useState([]) //when receiving data, it is filled with an array of true - the flag for hiding all transaction information on the mobile version
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(68)
  const [cashActiveCount, setCashActiveCount] = useState(null)

  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null })

  useEffect(() => {
    api.countActiveCashOperations().then((res) => {
      setCashActiveCount(res)
    })
  }, [])

  const historyElement = [
    '16.03.2023 13:33',
    'Withdraw',
    'EUR',
    '1 901 263.67',
    '0.00',
    'Internal transfer QWERTY12345QW56',
  ]
  const historyCash1Element = ['16.03.2023 13:33', 'CashIstanbul', 'Withdraw', '1 901 263.67', '0.00', 'Waiting']
  const historyCash2Element = ['16.03.2023 13:33', 'CashIstanbul', 'Withdraw', '1 901 263.67', '0.00', 'Canceled']
  const historyCash3Element = ['16.03.2023 13:33', 'CashIstanbul', 'Withdraw', '1 901 263.67', '0.00', 'Credited']
  const turnoverElement = ['16.03.2023', '0.00', '0.00', '0.00', '0.00', '0.00', '0.00']

  const CreateArray = (element) => {
    const newArray = []
    for (let i = 0; i < 20; i++) {
      newArray.push({ array: element })
    }
    return newArray
  }
  const CreateArrayFrom3Elem = (element1, element2, element3) => {
    const newArray = []
    for (let i = 0; i < 6; i++) {
      newArray.push(element1)
    }
    for (let i = 0; i < 6; i++) {
      newArray.push(element2)
    }
    for (let i = 0; i < 8; i++) {
      newArray.push(element3)
    }
    return newArray
  }
  const historyPageArray = CreateArray(historyElement)
  const historyCashPageArray = CreateArrayFrom3Elem(historyCash1Element, historyCash2Element, historyCash3Element)
  const turnoverPageArray = CreateArray(turnoverElement)

  const currentData = () => {
    switch (tab) {
      case Tabs.AccountHistory:
        return {
          titles: historyTitles,
          rows: filtredData,
          customClass: styles.rowHistory,
        }
      case Tabs.HistoryOfCash:
        return {
          titles: historyCashTitles,
          rows: filtredData,
          customClass: styles.rowHistoryCash,
        }
      case Tabs.HistoryOfTurnovers:
        return {
          titles: turnoverTitles,
          rows: turnoverPageArray, //data changes when a new page is requested
          customClass: styles.rowTurnover,
        }
    }
  }

  const reloadData = () => {
    setData([])
    if (tab === Tabs.AccountHistory || tab === Tabs.HistoryOfCash) {
      const isCash = tab === Tabs.HistoryOfCash
      api.fetchFinanceHistory(isCash).then((res) => {
        setHiddenList(res.map(() => true))
        const data = res.map((el) => {
          return {
            ...el,
            array: isCash
              ? [
                  format(new Date(el.date), 'dd.MM.yyyy HH:mm'),
                  t('CashIstanbul'),
                  el.type,
                  el.currencyCode,
                  el.amount,
                  el.publicId,
                  el.status,
                ]
              : [
                  format(new Date(el.date), 'dd.MM.yyyy HH:mm'),
                  el.type,
                  el.currencyCode,
                  el.amount,
                  `${el.feeAmount} ${el.feeCurrencyCode != el.currencyCode ? el.feeCurrencyCode : ''}`,
                  el.publicId || el.txId,
                ],
          }
        })
        setData(data)
        setFiltredData(data)
      })
    } else if (tab === Tabs.HistoryOfTurnovers) {
    }
  }

  const cancelOperation = (isDeposit, operationId) => {
    console.log('cancelOperation')
    const action = isDeposit ? api.cancelCashDeposit(operationId) : api.cancelCashWithdrawal(operationId)

    action
      .then((res) => {
        toast.success(t('ActionWasSuccessful'))
        reloadData()
      })
      .catch((err) => {
        console.log('cancelOperation error', err)
        toast.error(t('ErrorTryLater'))
      })
  }

  useEffect(() => {
    reloadData()
  }, [tab])

  useEffect(() => {
    if (!selectedDateRange.startDate) {
      setFiltredData(data)
      return
    }

    const startDate = new Date(selectedDateRange.startDate).getTime()
    const endDate = new Date(selectedDateRange.endDate).getTime()
    const filteredByDate = data.filter((el) => {
      const elDate = new Date(el.date).getTime()
      return elDate >= startDate && elDate <= endDate
    })
    setFiltredData(filteredByDate)
  }, [selectedDateRange])

  return (
    <>
      <section className={pages.blockWrapper}>
        <HistoryTabs tab={tab} setTab={setTab} setCurrentPage={setCurrentPage} cashActiveCount={cashActiveCount} />
        <BeforeTable tab={tab} selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
        <TableDesktop data={currentData()} cancelOperation={cancelOperation} />
        <TableMobile
          data={currentData()}
          cancelOperation={cancelOperation}
          hiddenList={hiddenList}
          setHiddenList={setHiddenList}
        />
      </section>
      {currentData().rows.length > 1 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          lastPage={Math.round(currentData().rows.length / 30)}
        />
      )}
    </>
  )
}

const HistoryTabs = ({ tab, setTab, setCurrentPage, cashActiveCount }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.tabWrapper}>
      {tabs.map((element, index) => {
        return (
          <div key={index}>
            <button
              className={cssClass(baseComponents.button, tab === element ? baseComponents.buttonActive : null)}
              onClick={() => {
                setTab(element)
                setCurrentPage(1)
              }}
            >
              {t(element)}
            </button>
            {element === Tabs.HistoryOfCash && cashActiveCount > 0 && (
              <div
                className={cssClass(
                  styles.notification1Figure,
                  cashActiveCount >= 10 ? styles.notificationMoreThan1Figure : null,
                )}
              >
                {cashActiveCount}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
const BeforeTable = ({ tab, selectedDateRange, setSelectedDateRange }) => {
  const { t } = useTranslation()

  const filters = ['All']
  const [filter, setFilter] = useState(filters[0])

  const filterAndTypeVisible = tab === Tabs.HistoryOfTurnovers
  const exportExcelVisible = tab === Tabs.AccountHistory
  const fullHistoryVisible = tab !== Tabs.HistoryOfTurnovers

  return (
    <>
      <div className={styles.optionsWrapper}>
        <div className={styles.fieldsWrapper}>
          {filterAndTypeVisible && (
            <>
              <FieldWrapper name={'Filter'}>
                <Selector list={filters} value={filter} selectNewItem={setFilter} />
              </FieldWrapper>
              <FieldWrapper name={'Type'}>
                <Selector list={filters} value={filter} selectNewItem={setFilter} />
              </FieldWrapper>
            </>
          )}
          <FieldWrapper isDate name={'Date'}>
            {tab === Tabs.AccountHistory && <DateInput dateRange={selectedDateRange} setDate={setSelectedDateRange} />}
            {tab === Tabs.HistoryOfCash && <DateInput dateRange={selectedDateRange} setDate={setSelectedDateRange} />}
            {tab === Tabs.HistoryOfTurnovers && (
              <DateInput dateRange={selectedDateRange} setDate={setSelectedDateRange} />
            )}
          </FieldWrapper>
        </div>
      </div>
    </>
  )
}
const FieldWrapper = ({ name, isDate, children }) => {
  const { t } = useTranslation()
  return (
    <div className={cssClass(styles.fieldWrapper, isDate && styles.fieldWrapperDate)}>
      <span className={styles.fieldTitle}>{t(name)}</span>
      {children}
    </div>
  )
}
const DateInput = ({ dateRange, setDate }) => {
  return (
    <div className={styles.dateInputsWrapper}>
      <div className={styles.dateInputWrapper}>
        <Datepicker
          addClasses={['p-calendar_secondary']}
          DatepickerCallback={(value) => {
            setDate(value)
          }}
          selectionMode='range'
          dateRange={dateRange}
        />
      </div>
    </div>
  )
}
const TableDesktop = ({ data, cancelOperation }) => {
  const { t } = useTranslation()
  const { titles, rows, customClass } = data
  return (
    <>
      <div className={cssClass(styles.table, styles.desktopOnly)}>
        <div className={cssClass(styles.row, styles.titleRow, customClass)}>
          {titles.map((element, index) => {
            if (element) {
              return (
                <div key={index} className={cssClass(styles.cell, styles.tableTitle)}>
                  {t(element)}
                </div>
              )
            }
          })}
        </div>

        <div className={styles.line}></div>
        {rows.map((row, indexRow) => {
          const isDeposit = row.type === 'Deposit'
          return (
            <div
              key={indexRow}
              className={cssClass(styles.row, (indexRow % 2) - 1 && styles.cellColoredBg, customClass)}
            >
              {row.array.map((element, indexElement) => {
                if (element) {
                  return (
                    <div
                      key={indexElement}
                      className={cssClass(
                        styles.cell,
                        titles[indexElement] === 'TxID' && styles.TxID,
                        element === 'Canceled' && styles.cancelled,
                        element === 'Credited' && styles.credited,
                      )}
                    >
                      {titles[indexElement] === 'Type' || titles[indexElement] === 'ReplenishmentAccount'
                        ? t(element)
                        : titles[indexElement] === 'Status'
                        ? t(`FinanceItemStatus_${element}`)
                        : element}
                      {element === 'Waiting' && (
                        <>
                          <span>/</span>
                          <button className={styles.cancelButton} onClick={() => cancelOperation(isDeposit, row.txId)}>
                            {t('CancelVerb')}
                          </button>
                        </>
                      )}
                    </div>
                  )
                }
              })}
            </div>
          )
        })}
      </div>
    </>
  )
}
const TableMobile = ({ data, cancelOperation, hiddenList, setHiddenList }) => {
  const { t } = useTranslation()
  const { titles, rows, customClass } = data
  return (
    <div className={cssClass(styles.table, styles.mobileOnly)}>
      {rows.map((row, index) => {
        const element = row.array
        const isDeposit = row.type === 'Deposit'
        return (
          <Fragment key={index}>
            <div className={styles.lineMobile}></div>
            <div
              onClick={() => setHiddenList((prev) => [...prev.slice(0, index), !prev[index], ...prev.slice(index + 1)])}
              className={cssClass(styles.row, styles.titleRow, styles.rowMobile, baseComponents.pointer)}
            >
              <div className={cssClass(styles.cell, styles.tableTitle, styles.rowMobileTitleWrapper)}>
                <span className={styles.grey}>{t(titles[0])}</span> <span>{element[0]}</span>
              </div>
              <img className={!hiddenList[index] ? styles.imgRotate : null} src={arrowLeftListIimg} alt='' />
            </div>
            <div className={hiddenList[index] ? styles.hiddenWrapper : null}>
              {titles.map((rowTitle, indexRowTitle) => {
                if (indexRowTitle) {
                  return (
                    <Fragment key={indexRowTitle}>
                      <div
                        className={cssClass(styles.row, styles.rowMobile, indexRowTitle % 2 && styles.cellColoredBg)}
                      >
                        <div className={cssClass(styles.cell)}>{t(titles[indexRowTitle])}</div>
                        <div
                          className={cssClass(
                            styles.cell,
                            titles[indexRowTitle] === 'TxID' && styles.TxID,
                            element[indexRowTitle] === 'Canceled' && styles.cancelled,
                            element[indexRowTitle] === 'Credited' && styles.credited,
                          )}
                        >
                          {titles[indexRowTitle] === 'Type' || titles[indexRowTitle] === 'ReplenishmentAccount'
                            ? t(element[indexRowTitle])
                            : titles[indexRowTitle] === 'Status'
                            ? t(`FinanceItemStatus_${row.status}`)
                            : element[indexRowTitle]}
                          {element[indexRowTitle] === 'Waiting' && (
                            <>
                              <span>/</span>
                              <button
                                className={styles.cancelButton}
                                onClick={() => cancelOperation(isDeposit, row.txId)}
                              >
                                {t('CancelVerb')}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )
                }
                return null
              })}
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}
const Pagination = ({ currentPage, setCurrentPage, lastPage }) => {
  return (
    <div className={styles.paginationWrapper}>
      <button
        onClick={() => currentPage - 1 > 0 && setCurrentPage((prev) => prev - 1)}
        className={cssClass(baseComponents.buttonOutlined, styles.arrowButton)}
      >
        <img src={arrowLeftIimg} alt='Arrow left' />
      </button>
      <div className={styles.paginationCenterWrapper}>
        {currentPage > 3 && (
          <span onClick={() => setCurrentPage(1)} className={styles.pageNumber}>
            1
          </span>
        )}
        {currentPage > 3 && lastPage > 4 && <EllipsisPage currentPage={currentPage} setCurrentPage={setCurrentPage} />}
        {lastPage > 5 && (
          <>
            {currentPage === 3 && (
              <span onClick={() => setCurrentPage(currentPage - 2)} className={styles.pageNumber}>
                {currentPage - 2}
              </span>
            )}
            {currentPage - 1 !== 0 && (
              <span onClick={() => setCurrentPage(currentPage - 1)} className={styles.pageNumber}>
                {currentPage - 1}
              </span>
            )}
            <span className={cssClass(styles.pageNumber, styles.pageNumberActive)}>{currentPage}</span>
            {lastPage - currentPage > 1 && (
              <span onClick={() => setCurrentPage(currentPage + 1)} className={styles.pageNumber}>
                {currentPage + 1}
              </span>
            )}
            {lastPage - currentPage === 2 && (
              <span onClick={() => setCurrentPage(currentPage + 2)} className={styles.pageNumber}>
                {currentPage + 2}
              </span>
            )}
          </>
        )}
        {lastPage - currentPage > 2 && lastPage > 5 && (
          <EllipsisPage currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
        {currentPage !== lastPage && lastPage - currentPage !== 2 && (
          <span onClick={() => setCurrentPage(lastPage)} className={styles.pageNumber}>
            {lastPage}
          </span>
        )}
      </div>

      <button
        onClick={() => lastPage - currentPage > 0 && setCurrentPage((prev) => prev + 1)}
        className={cssClass(baseComponents.button, styles.arrowButton, styles.arrowRight)}
      >
        <img src={arrowLeftIimg} alt='Arrow left' />
      </button>
    </div>
  )
}
const EllipsisPage = ({ currentPage, setCurrentPage }) => {
  const [value, setValue] = useState('...')
  const refInput = useRef(null)

  useEffect(() => {
    refInput.current.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        setCurrentPage(parseInt(e.target.value))
      }
    })
  })
  return (
    <span className={cssClass(styles.pageNumber, styles.ellipsisWrapper)}>
      <input
        ref={refInput}
        className={cssClass(baseComponents.input, baseComponents.buttonOutlined, styles.ellipsis)}
        type='text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClick={() => setValue('')}
        onBlur={() => setValue('...')}
      />
    </span>
  )
}
