import React, { useState } from 'react'

import styles from './Chart.module.scss'
import ChartElement from './ChartElement'
import Orderbook from '../Orderbook/Orderbook'
import ChartTradingView from './ChartTradingView'

export default function Chart() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.chartTable}>
        {/* <ChartElement isPhone={false} /> */}
        <ChartTradingView />
        <Orderbook />
      </div>
      <div className={styles.phoneOnly}>
        {/* <ChartElement isPhone={true} /> */}
        <ChartTradingView />
      </div>
    </div>
  )
}
