import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import qs from 'qs'
import { observer } from 'mobx-react'
import store from '../store'
import { cssClass } from 'helpers/helper'

import styles from './CurrencyPairs.module.scss'
import stylesTrading from '../TradingPage.module.scss'
import pairs from 'data/TradingPairs'

export default function CurrencyPairs({ selectedPair, prevSelectedPair, forLandingPage = false }) {
  const wrapperStyle = forLandingPage
    ? cssClass(stylesTrading.firstRowWrapper, stylesTrading.firstRowWrapperLanding)
    : stylesTrading.firstRowWrapper

  return (
    <React.Fragment>
      {/* <div className={wrapperStyle}>
        {!forLandingPage && <LiteProSwitsher />}
        <div className={stylesTrading.currencyButtonsWrapper}>
          {currenciesSelect.map((currency) => {
            const isActive = currency === selectedCurrency
            const activityClass = isActive ? stylesTrading.currencyButtonActive : null
            return (
              <button
                key={currency}
                onClick={() => setSelectedCurrency(currency)}
                className={cssClass(stylesTrading.currencyButton, activityClass)}
              >
                {currency}
              </button>
            )
          })}
        </div>
      </div> */}
      <CurrencyPairsList selectedPair={selectedPair} prevSelectedPair={prevSelectedPair} />
    </React.Fragment>
  )
}
const CurrencyPairsList = observer(({ selectedPair, prevSelectedPair }) => {
  const isContainsPair = pairs.includes(selectedPair)
  const pairToInsert = isContainsPair ? prevSelectedPair : selectedPair
  const pairsToShow = pairToInsert ? [pairToInsert, ...pairs] : pairs

  return (
    <div className={styles.currencyPairBlocksWrapper}>
      {pairsToShow.map((pairCode) => {
        return <CurrencyPairBlock key={pairCode} pairCode={pairCode} />
      })}
    </div>
  )
})

const CurrencyPairBlock = observer(({ pairCode }) => {
  const isActive = store.symbol === pairCode
  const pairData = store.instruments?.find((i) => i.code === pairCode)
  let price = 0,
    priceChange = 0
  if (pairData) {
    price = pairData.price || 0
    priceChange = pairData.price24hAgo // check dividsion by zero
      ? ((pairData.price - pairData.price24hAgo) / pairData.price24hAgo) * 100
      : 0
  }

  const calcBgClass = () => {
    if (priceChange >= 0) {
      return isActive ? styles.currencyPairBlockActive : styles.currencyPairBlockGreen
    } else {
      return isActive ? styles.currencyPairBlockActive : styles.currencyPairBlockRed
    }
  }
  const calcProfitClass = () => {
    if (priceChange >= 0) {
      return isActive ? styles.currencyPairProfitGreenActive : styles.currencyPairProfitGreen
    } else {
      return isActive ? styles.currencyPairProfitRedActive : styles.currencyPairProfitRed
    }
  }
  const calcChartClass = () => {
    if (priceChange >= 0) {
      return isActive ? styles.currencyPairChartGreenActive : styles.currencyPairChartGreen
    } else {
      return isActive ? styles.currencyPairChartRedActive : styles.currencyPairChartRed
    }
  }

  const chartClass = calcChartClass()
  const profitClass = cssClass(styles.currencyPairPofit, calcProfitClass())
  const bgClass = cssClass(styles.currencyPairBlock, calcBgClass())
  const history = useHistory()
  const match = useRouteMatch()

  const handlePairClick = (code) => {
    store.setSymbol(code)
    if (!isActive) {
      console.log('CHANGE SYMBOL TO', code)
      const query = qs.stringify({ symbol: code }, { addQueryPrefix: true })
      history.push(`${match.url}${query}`)
    }
  }
  return (
    <div className={bgClass} onClick={() => handlePairClick(pairCode)}>
      <div className={profitClass}>
        {priceChange > 0 ? '+' : ''}
        {priceChange.toFixed(2)}%
      </div>
      <span className={styles.currencyPair}>{pairCode?.replace('_', ' / ')}</span>
      {/* <span className={styles.currencyPairValue}>{price}</span>
      <img className={chartClass} src='/images/chart.svg' alt='Chart' /> */}
    </div>
  )
})

const LiteProSwitsher = () => {
  return (
    <div className={stylesTrading.liteProSwitcherWrapper}>
      <span>LITE</span>
      <input className={stylesTrading.input} type='checkbox' id='switcher' />
      <label className={stylesTrading.label} htmlFor='switcher'>
        Toggle
      </label>
      <span>PRO</span>
    </div>
  )
}
