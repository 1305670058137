import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import arrow from './images/arrow.png'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
const navList = [
  {
    text: 'Convert',
    link: '/convert',
    submenu: [],
  },
  {
    text: 'Exchange',
    link: '/exchange',
    submenu: [],
  },
  {
    text: 'Assets',
    link: '/finance',
    submenu: [],
  },
  {
    text: 'Market',
    link: '/tokens/markets',
    submenu: [],
  },
  {
    text: 'BranchOffices',
    link: '/offices',
    submenu: [],
  },
  {
    text: 'Information',
    link: `/`,
    submenu: [
      {
        text: 'RegistrationAndVerification',
        link: '/onenews/22',
      },
      {
        text: 'SellCryptocurrency',
        link: '/onenews/49',
      },
      {
        text: 'ReplenishmentAndWithdrawalOfBalance',
        link: '/onenews/36',
      },
      {
        text: 'ExchangeCryptocurrencyInIstanbul',
        link: '/onenews/83',
      },
      {
        text: 'BuyCryptocurrencyInIstanbul',
        link: '/onenews/48',
      },
      {
        text: 'TransferMoneyFromRussiaToTurkey',
        link: '/onenews/56',
      },
      {
        text: 'TransferMoneyFromTurkeyToOtherCountries',
        link: '/onenews/57',
      },
      {
        text: 'CommentsPerevood',
        link: '/onenews/60',
      },

      {
        text: 'ServicesSubtitle1',
        link: '/purchaseAtCost',
      },
      {
        text: 'ServicesSubtitle3',
        link: '/financial-cooperation',
      },
      {
        text: 'SoftwareDevelopment',
        link: '/software',
      },
      {
        text: 'Commissions',
        link: '/commissions',
      },
      {
        text: 'TradableAssets',
        link: '/tradable',
      },
      {
        text: 'CashOperations',
        link: '/cash-operations',
      },
      {
        text: 'TradingView',
        link: '/onenews/170',
      },
    ],
  },
]
export default function HeaderNavigation() {
  const { t } = useTranslation()
  const [navIndex, setNavIndex] = useState(false)
  return (
    <nav className='header__nav nav'>
      <ul className='nav__list'>
        {navList.map((item, i) => (
          <li
            className={`nav__item ${navIndex === i ? 'nav__item_open' : ''} ${
              item.link === '' ? 'nav__item_disabled' : ''
            }  `}
            key={i}
          >
            <NavLink to={`/${i18n.language}${item.link}`} className={'nav__link'} exact={true}>
              <span className='nav__text'>{t(item.text)}</span>
            </NavLink>
            {item.submenu.length ? (
              <React.Fragment>
                <span className='nav__action' onClick={() => (navIndex === i ? setNavIndex(false) : setNavIndex(i))}>
                  <img src={arrow} alt='image description' />
                </span>
                <ul className='nav__submenu submenu'>
                  {item.submenu.map((subItem, index) => (
                    <li className={`nav__item ${!subItem.link.length ? 'nav__item_disabled' : ''}`} key={index}>
                      <NavLink to={`/${i18n.language}${subItem.link}`} className='nav__link' exact={true}>
                        <span className='nav__text'>{t(subItem.text)}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  )
}
